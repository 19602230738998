<template>
    <div class="htr-widget">
        <div class="htr-widget-inner">
            <div class="htr-widget-content">
                <span class="text">Verified by</span>
                <a class="htr-widget-logo">
                    <img :src="asset('/images/logo_s.svg')" alt="Hotel Tech Report" width="88">
                </a>
                <stars :value="widget.stars" :asset="asset"></stars>
                <span class="htr-widget-reviews">{{ widget.reviews_count }} reviews</span>
            </div>
            <a :href="widget.url" class="htr-widget-link" target="_blank" rel="noopener">
                <span>Read our reviews</span><img :src="asset('/images/arrow-right.svg')" width="17" height="17">
            </a>
        </div>
        <div :id="`particles-${rand}`" class="htr-widget-particles"></div>
    </div>
</template>

<script>
    import axios from 'axios';
    import particles from './particles.json';
    import Stars from './Stars.vue';
    import {each} from 'lodash';

    export default {
        components: {Stars},
        props: ['companyId'],
        data() {
            return {
                rand: Math.ceil(Math.random() * 1000) + "",
                widget: {
                    stars: 0,
                    reviews_count: 0,
                    url: '',
                }
            }
        },
        mounted() {
            window.particlesJS(`particles-${this.rand}`, particles);
            axios.get(`${this.host}/api/widgets/${this.companyId}`).then(res => {
                this.widget = res.data;
                this.addRichSnippet();
            });
        },
        methods: {
            asset(path) {
                return `${this.host}${path}`
            },
            addRichSnippet() {
                let existing = find(document.getElementsByTagName('script'), script => script.type === 'application/ld+json');
                if(existing) return;

                let script = document.createElement('script');
                script.type = 'application/ld+json';
                script.innerHTML = JSON.stringify({
                    '@context': 'http://schema.org',
                    '@type': 'Product',
                    'aggregateRating': {
                        '@type': 'AggregateRating',
                        'ratingValue': this.widget.stars,
                        'reviewCount': this.widget.reviews_count
                    },
                    'description': this.widget.tagline,
                    'name': this.widget.name
                });
                document.getElementsByTagName('head')[0].appendChild(script);
            }
        },
        computed: {
            host() {
                return process.env.MIX_APP_URL || 'https://hoteltechreport.com'
            }
        }
    }
</script>

<style lang="scss" scoped>
@import "./style";
</style>
