<template>
    <ul class="htr_star-rating">
        <li><img :src="star(1)" width="16" :alt="starAlt(1)"></li>
        <li><img :src="star(2)" width="16" :alt="starAlt(2)"></li>
        <li><img :src="star(3)" width="16" :alt="starAlt(3)"></li>
        <li><img :src="star(4)" width="16" :alt="starAlt(4)"></li>
        <li><img :src="star(5)" width="16" :alt="starAlt(5)"></li>
    </ul>
</template>

<script>
    export default {
        props: ['value', 'asset'],
        methods: {
            starAlt(n) {
              if(n <= this.value) return "full star";
              if(this.value % 1 && Math.ceil(this.value) === n)  return "half star";
              if(Math.ceil(this.value) <= n) return "empty star"
            },
            star(n) {
                if(n <= this.value) return this.asset('/images/star_full.svg');
                if(this.value % 1 && Math.ceil(this.value) === n)  return this.asset('/images/star_half.svg');
                if(Math.ceil(this.value) <= n) return this.asset('/images/star_empty.svg')
            }
        }
    }
</script>

<style lang="scss" scoped>
    .htr_star-rating {
        line-height: 17.3px;
        margin: 0 0 5px;
        padding: 0;
        list-style: none;

        li {
            display: inline-block;
            vertical-align: middle;
            margin-right: 1px;
            width: 16px;

            &:last-child {
                margin-right: 0;
            }
        }
    }
</style>
