import './molecules.js';
import Vue from 'vue';
import Widget from './Widget.vue';

function widget(selector, id) {
    new Vue({ components: {Widget}, el: selector, template: `<widget company-id="${id}"></widget>`})
}

window.HTR = {widget: widget};
var callbacks = window.htr_callbacks || [];
for(var i in callbacks) {
    if(callbacks.hasOwnProperty(i)) {
        callbacks[i](widget)
    }
}